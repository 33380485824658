import React, { ReactNode } from 'react'
import classnames from 'classnames'
import * as styles from './index.module.less'

interface SectionProps {
  className?: string
  children?: ReactNode
  title?: string
}

const Section: React.FC<SectionProps> = (props) => {
  const { className = '', children, title } = props
  return (
    <section className={classnames(styles.section, className)}>
      <div className="en-section">
        {title && <h2 className="en-section-title">{title}</h2>}
        {children}
      </div>
    </section>
  )
}

export default Section
