import React from 'react'
import Button from 'components/Base/Button'
import * as styles from './index.module.less'

interface NewBannerProps {
  className?: string
  title: string
  desc: string
  buttons?: Array<{ button_text: string; button_href: string; button_type: string; button_class?: string }>
  video_img?: any
  clickVideo?: () => void
}

const NewBanner = (props) => {
  const { title, desc, buttons, video_img, clickVideo } = props
  const handleClickVideo = () => {
    clickVideo?.()
  }
  return (
    <div className={styles.banner}>
      <div className="banner-container">
        <div className="banner-left">
          <h2 className="banner-left-title">{title}</h2>
          <p className="banner-left-desc">{desc}</p>
          <div className="banner-btns">
            <Button className="banner-btn-left" href={buttons[0].button_href} btnType={buttons[0].button_type}>
              {buttons[0].button_text}
            </Button>
            {!!buttons[1]?.button_text && (
              <Button className="banner-btn-right" onClick={handleClickVideo} btnType={buttons[1].button_type}>
                {buttons[1].button_text}
              </Button>
            )}
          </div>
        </div>
        <div className="banner-right">
          <img className="banner-right-img" src={video_img?.publicURL || video_img} alt={title} />
        </div>
      </div>
    </div>
  )
}

export default NewBanner
