import React, { useState, useRef } from 'react'
import Button from 'components/Base/Button'
import SwiperCore, { Scrollbar, A11y, Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import useIsWindowWidthSmaller from 'hooks/useWindowWidth'
import classnames from 'classnames'
import * as styles from './index.module.less'
import 'swiper/swiper.less'
import 'swiper/components/scrollbar/scrollbar.less'
SwiperCore.use([Scrollbar, A11y, Autoplay])

const SolutionTabs = ({ dataSource, handleClickVideo }) => {
  const swiperRef = useRef<SwiperCore>()
  const [activeIndex, setActiveIndex] = useState<number>(0)
  const isMb = useIsWindowWidthSmaller()
  const handleClick = (index) => {
    return () => {
      if (swiperRef.current) {
        swiperRef.current.slideTo(index) // 设置当前激活幻灯片索引
      }
    }
  }
  const handlePlayVideo = (video_source) => {
    return () => {
      handleClickVideo?.(video_source)
    }
  }
  const handleSlideChange = (swiper) => {
    setActiveIndex(swiper.activeIndex)
  }

  return (
    <div className={styles.tabs}>
      <div className="flex solution-tabs">
        {dataSource?.map((item, index) => {
          return (
            <div
              className={classnames('solution-tab-item ', { 'solution-tab-item_active': index === activeIndex })}
              onClick={handleClick(index)}
              key={item.desc}
            >
              {item.title}
            </div>
          )
        })}
      </div>
      {isMb ? (
        dataSource.map((item) => {
          return (
            <div className={classnames('solution-tab-content')} key={item.title}>
              <div className="solution-tab-content-left" onClick={handlePlayVideo(item.video_source)}>
                <img className="rounded-[16px]" src={item.img?.publicURL || item.img} alt={item.title} />
              </div>
              <div className="solution-tab-content-right">
                <h3 className="solution-tab-content-right-title">{item.title}</h3>
                <p className="solution-tab-content-right-desc">{item.desc}</p>
                <div className="solution-tab-content-right-btns">
                  <Button
                    btnType="international"
                    className="solution-tab-content-right-btns-left"
                    href={item.buttons[0].button_href}
                  >
                    {item.buttons[0].button_text}
                  </Button>
                  <Button
                    btnType="black"
                    className="solution-tab-content-right-btns-right"
                    onClick={handlePlayVideo(item.video_source)}
                  >
                    {item.buttons[1].button_text}
                  </Button>
                </div>
              </div>
            </div>
          )
        })
      ) : (
        <Swiper
          onSwiper={(swiper) => {
            swiperRef.current = swiper
          }}
          spaceBetween={10}
          onSlideChange={handleSlideChange}
          autoplay={{
            delay: 10000, // 设置自动轮播的时间间隔为 10 秒
            disableOnInteraction: true, // 用户交互后是否停止自动轮播
          }}
          scrollbar={{ draggable: true }}
        >
          {dataSource.map((item) => {
            return (
              <SwiperSlide className={classnames('solution-tab-content')} key={item.title}>
                <div className="solution-tab-content-left" onClick={handlePlayVideo(item.video_source)}>
                  <img className="rounded-[16px]" src={item.img?.publicURL || item.img} alt={item.title} />
                </div>
                <div className="solution-tab-content-right">
                  <h3 className="solution-tab-content-right-title">{item.title}</h3>
                  <p className="solution-tab-content-right-desc">{item.desc}</p>
                  <div className="solution-tab-content-right-btns">
                    <Button
                      btnType="international"
                      className="solution-tab-content-right-btns-left"
                      href={item.buttons[0].button_href}
                    >
                      {item.buttons[0].button_text}
                    </Button>
                    <Button
                      btnType="black"
                      className="solution-tab-content-right-btns-right"
                      onClick={handlePlayVideo(item.video_source)}
                    >
                      {item.buttons[1].button_text}
                    </Button>
                  </div>
                </div>
              </SwiperSlide>
            )
          })}
        </Swiper>
      )}
    </div>
  )
}

export default SolutionTabs
