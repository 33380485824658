import React from 'react'
import GartnerWidget from 'components/GartnerWidget'
import Button from 'components/Base/Button'
import classnames from 'classnames'
import * as styles from './index.module.less'

const GartnerCard = (props) => {
  const { className = '', content, button_text, button_href, button_type, widget_id } = props
  return (
    <div className={classnames(styles.gartnerCard, className)}>
      <div className="gartner-card-left">
        <div className="gartner-widget-wrapper">
          <GartnerWidget widget_id={widget_id} />
        </div>
      </div>
      <div className="gartner-card-right">
        <div className="gartner-card-right-content">{content}</div>
        <div className="gartner-card-right-btn">
          <Button btnType={button_type} href={button_href}>
            {button_text}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default GartnerCard
